import React from "react"

class Footer extends React.Component {


	componentDidMount() {
        // console.log(this.props.data);
        document.querySelector('#elementor-footer-css').href=process.env.API_URL + '/wp-content/uploads/elementor/css/post-' + this.props.template.wordpress_id + '.css';
        console.log(this.props);

    }
// export default ({ data }) => {
    render() {   
      
        // const content = this.props.template.content.replace(/http:\/\/barder.loopmill.com/g, "")
        const content = this.props.template.content

        return(

            <div dangerouslySetInnerHTML={{ __html: content }} />

        )
                
    }
}

export default Footer;

