import React from "react"

class Header extends React.Component {

	componentDidMount() {
        // console.log(this.props.data);
        document.querySelector('#elementor-header-css').href=process.env.API_URL + '/wp-content/uploads/elementor/css/post-' + this.props.template.wordpress_id + '.css';
        console.log(this.props);

    }
// export default ({ data }) => {
    render() {   
      
        const content = this.props.template.content.replace(/href="http:\/\/barder.loopmill.com/g, 'href="')
        // const content = this.props.template.content;

        return(

            <div dangerouslySetInnerHTML={{ __html: content }} />

        )
                
    }
}

export default Header;

